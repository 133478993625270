@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@media print
{
.noprint {display:none;}
}

  div {
    font-family:Roboto;
  }
  h3 div {
    font-family:Roboto;
    line-height:11px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top:10;
    left:10;
    background-color: #765674;
    min-width: 160px;
    overflow: auto;
    /* box-shadow: 0px 8px 16px 0px rgba(185, 11, 11, 0.2); */
    z-index: 99;
    font-family: Roboto;
  }
  
  .dropdown-content a {
    color: rgb(194, 220, 208);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown a:hover {background-color: #567658;color:#ffffff; box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5);}
  
  .show {display: block;}
  .hide {display: none;}